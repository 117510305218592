<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="productGrid"
          ref="amzInventoryGridRef"
          :data-source="amazonInventorySource"
          :height="setHeight"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @cell-dbl-click="onCellDblClick"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
          @exporting="onExporting"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="true" :filtering="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column caption="Actions" alignment="center" cell-template="itemActions" :width="140" :allow-sorting="false" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="success" icon="bi bi-file-bar-graph"
                class="mr-half"
                hint="Sales Order Metrics"
                @click="onOpenOrderMetrics(data)"
              />
              <dx-util-button
                :disabled="data.data.inventoryType === 'AFN'"
                type="danger"
                icon="bi bi-trash"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" cell-template="titleCellTemplate" :min-width="300" :allow-sorting="false" />
          <template #titleCellTemplate="{data}">
            <small-info-card :product="data.data" @emit-open-product-details="openProductDetails" />
          </template>
          <dx-column data-field="upc" caption="UPC" width="auto" :allow-sorting="false" cell-template="upcCellTemplate" />
          <template #upcCellTemplate="{data}">
            <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="asin" caption="ASIN" :width="120" :allow-sorting="false" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="msku" caption="MSKU" width="auto" :allow-sorting="false" />
          <dx-column data-field="fnsku" caption="FNSKU" width="auto" :allow-sorting="false" />
          <dx-column data-field="condition" width="auto" :allow-sorting="false" />
          <dx-column data-field="inventoryType" caption="Type" width="60" alignment="center" cell-template="inventoryTypeCell" :allow-sorting="false" />
          <template #inventoryTypeCell="{ data }">
              <span v-if="data.value" class="d-block badge" :class="getTypeFormated(data.value).variant">
                {{ getTypeFormated(data.value).text }}
              </span>
            </template>
          <dx-column data-field="sellPrice" :format="'$#,##0.##'" width="auto" />
          <dx-column data-field="total" caption="On Hand" width="auto" cell-template="onHandTemplate">
          </dx-column>
          <template #onHandTemplate="{data}">
            <div v-if="data.data.inventoryType === 'MFN'" class="d-flex justify-content-around">
              <span>{{ data.value }}</span>
              <p-icon name="bi-pencil-square" role="button" color="warning" @click.native="openAmzInventoryUpdater(data.data)" />
            </div>
            <div v-else class="d-flex justify-content-around">
              <span>{{ data.value }}</span>
            </div>
          </template>
          <dx-column data-field="inbound" width="auto" />
          <dx-column data-field="fulfillable" caption="Fulfillable" width="auto" />
          <dx-column data-field="unsellable" caption="Unsellable" width="auto" />
          <dx-column data-field="reserved" caption="Reserved" width="auto" />
          <dx-column v-if="hasPermission" data-field="accountNo" caption="Acc #" width="auto" alignment="center" :allow-sorting="false" cell-template="accountCell" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <dx-column data-field="storeName" caption="Store Name" width="auto" :allow-sorting="false" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedInventoryType"
                  :data-source="inventoryTypes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <dx-util-check-box
                  v-model="hasInventory"
                  text="Has Inventory"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
    <dx-util-popup
      ref="OrderMetricsRef"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      width="95vw"
      height="90vh"
      title="Order Metrics"
      @hidden="onHiddenOrderMetrics"
    >
      <div>
        <order-metrics :key="rerenderKey" :asin="itemSalesMetrics.asin" :store-id="itemSalesMetrics.storeId" :product-id="itemSalesMetrics.productId" />
      </div>
    </dx-util-popup>
    <amazon-inventory-updater :component-id="amzInventoryCompId" :row="selectedRow" @quantity-changed="searchByFilter" />
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import CoolLightBox from 'vue-cool-lightbox'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import inventoryService from '@/http/requests/inventory/inventoryService'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import SmallInfoCard from '@/views/apps/inventory/components/InventoryProductInfoCard.vue'
import useConfirmation from '@/libs/app/confirmation'
import { isValidAsin } from '@/views/apps/ship/validationItem'
import Filters from '@robustshell/mixins/filters'
import fulfillmentNetworkEnum, { getList } from '@/enums/fulfillmentNetwork.enum'
import { amazonInventorySource } from './amazonInventoryStore.js'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AmazonInventoryUpdater from '../components/AmazonInventoryUpdater'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'order-metrics': () => import('@/views/apps/reports/orders/OrderMetrics.vue'),
    'small-info-card': SmallInfoCard,
    'amazon-inventory-updater': AmazonInventoryUpdater,
  },
  mixins: [GridBase, Filters],
  setup() {
    const { pshConfirm } = useConfirmation()
    return { pshConfirm }
  },
  data: () => ({
    popupVisible: false,
    amazonInventorySource,
    accountNo: '',
    selectedPackType: 'all',
    selectedProductId: null,
    selectedInventoryType: fulfillmentNetworkEnum.ALL.value,
    inventoryTypes: getList(),
    hasInventory: true,
    query: '',
    defaultImg: defaultImageUrl,
    images: [],
    index: null,
    isProductDetailsVisible: false,
    isPlanSelectorVisible: false,
    itemSalesMetricsBlank: {
      storeId: 0,
      productId: null,
      asin: '',
    },
    itemSalesMetrics: {},
    rerenderKey: 0,
    amzInventoryCompId: '',
    selectedRow: {},
  }),
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    orderMetricsPopup() {
      return this.$refs.OrderMetricsRef.instance
    },
    dataGrid() {
      const dataGridIns = this.$refs.amzInventoryGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
  },
  watch: {
    accountNo(current) {
      if (!current) {
        this.onClearSelected()
        this.getStores()
      }
    },
    selectedStore(current, old) {
      if (current !== old) {
        this.onClearSelected()
      }
    },
  },
  created() {
    this.getStores()
    this.loadData()
  },
  methods: {
    openAmzInventoryUpdater(data) {
      this.selectedRow = data
      this.amzInventoryCompId = this.$uid()
    },
    loadData() {
      InventorySearchFilter.setDefaultFilters()
      const filters = InventorySearchFilter.getFilters()
      amazonInventorySource.searchValue(filters)
    },
    searchByFilter() {
      InventorySearchFilter.accountNo = this.accountNo
      InventorySearchFilter.storeId = this.selectedStore
      InventorySearchFilter.warehouseId = this.selectedWarehouse
      InventorySearchFilter.q = this.query
      InventorySearchFilter.hasInventory = this.hasInventory
      InventorySearchFilter.inventoryType = this.selectedInventoryType
      const filters = InventorySearchFilter.getFilters()
      amazonInventorySource.searchValue(filters)
      amazonInventorySource.reload()
    },
    onClearSelected() {
      this.dataGrid.clearSelection()
      this.searchByFilter()
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          dropDownOptions: {
            width: 240,
          },
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items (max 2000)',
              icon: 'selectall',
            },
            {
              id: 'current',
              name: 'Current Page Items',
              icon: 'export',
            },
          ],
          width: 120,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipment Items',
          onItemClick: arg => {
            switch (arg.itemData.id) {
              case 'current':
                this.onDownloadPageItems()
                break
              default:
                this.includeDetails = false
                this.onDownloadItems()
                break
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadGrid()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.data.accountNo.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    reloadGrid() {
      this.accountNo = ''
      this.selectedPackType = 'all'
      this.query = ''
      this.dataSource.reload()
    },
    openProductDetails(productId) {
      this.selectedProductId = productId
      this.isProductDetailsVisible = true
    },
    onOpenOrderMetrics(e) {
      const item = { ...e.row.data }
      if (!isValidAsin(item.asin)) {
        Notify.danger('Seleceted item does not have a valid asin')
        return
      }
      Object.assign(this.itemSalesMetrics, {
        storeId: item.storeId,
        productId: item.productId,
        asin: item.asin,
      })
      this.rerenderKey += 1
      this.orderMetricsPopup.show()
    },
    async onClickDelete(e) {
      const { storeId, msku } = e.data
      const confirm = await this.pshConfirm(
        'Delete Confirmation',
        `<p>Are you sure you want to delete this listing with msku: <span style="color: orange;">${msku}</span> on Amazon?</p>
         <h4 class="text-danger mt-half">You item will be deleted from Amazon.</h4>`,
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'default',
        true,
        'DELETE',
        'Type DELETE to confirm',
      )
      if (confirm) {
        try {
          await amazonReportService.deleteAmazonListing(storeId, msku)
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    onHiddenOrderMetrics() {
      this.itemSalesMetrics = this.itemSalesMetricsBlank
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageIndex') {
        this.pageIndex = e.value
      }
      this.pageSize = this.$refs.amzInventoryGridRef.instance.pageSize()
    },
    async onDownloadInventory(e) {
      const filters = InventorySearchFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await inventoryService.fetchByQuery(filters, pageableQuery)
      const data = response.data.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedInventory() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    getTypeFormated(type) {
      if (type === 'AFN') return { variant: 'badge-light-warning', text: 'AFN' }
      if (type === 'MFN') return { variant: 'badge-light-primary', text: 'MFN' }
      return { variant: 'secondary', text: '' }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Amazon Inventory ${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'MSKU', key: 'msku', width: 30 },
        { header: 'FNSKU', key: 'fnsku', width: 14 },
        { header: 'Amazon Inbound', key: 'amzInbound', width: 14 },
        { header: 'Amazon Fulfillable', key: 'amzFulfillable', width: 14 },
        { header: 'Amazon Unsellable', key: 'amzUnsellable', width: 14 },
        { header: 'Reserved', key: 'reserved', width: 14 },
        { header: 'On Hand', key: 'total', width: 12 },
        { header: 'Store', key: 'storeName', width: 20 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.upc,
          item.asin,
          item.msku,
          item.fnsku,
          item.amzInbound,
          item.amzFulfillable,
          item.amz.Unsellable,
          item.reserved,
          item.total,
          item.storeName,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Amazon Inventory ${fileDate}.xlsx`,
        )
      })
    },
  },
}
</script>

<style lang="scss">
td[role=columnheader] {
    text-align: center!important
}

.text-caption {
    font-size: 10px;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
