<template>
  <dx-util-popup
    ref="amzInventoryUpdaterPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    width="460"
    height="auto"
    :full-screen="false"
    :position="'center'"
    @shown="amzInventoryUpdaterPopupShown"
    @hidden="amzInventoryUpdaterPopupHidden"
  >
    <div>
      <div v-if="showMessage" class="alert alert-warning">
        We have just fetched your Amazon Inventory to show the most recent quantity and reflected the change below.
      </div>
      <div class="text-info">
        Amazon Current Quantity: {{ newQuantity }}
      </div>
      <label for="onHand">New Quantity</label>
      <dx-util-number-box id="onHand" v-model="newQuantity" />
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      onHand: 0,
      newQuantity: 0,
      showMessage: false,
    }
  },
  computed: {
    amzInventoryUpdaterPopup() {
      return this.$refs.amzInventoryUpdaterPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.amzInventoryUpdaterPopup.show()
        this.fetchOnHand()
      },
    },
  },
  methods: {
    async fetchOnHand() {
      const recentQuantity = await amazonReportService.getInventoryByMsku(this.row.storeId, this.row.msku)
      this.onHand = recentQuantity
      this.newQuantity = recentQuantity
      if (this.row.total !== recentQuantity) {
        this.showMessage = true
        this.$emit('quantity-changed')
      }
    },
    async updateOnHand() {
      await amazonReportService.updateAmazonOnHandInventory(this.row.storeId, this.row.msku, this.newQuantity)
      this.$emit('quantity-changed')
    },
    amzInventoryUpdaterPopupShown() {
      this.popupTitle = 'Amazon Inventory Updater'
    },
    amzInventoryUpdaterPopupHidden() {
    // Clear form when popup is closed
      this.onHand = 0
      this.newQuantity = 0
      this.showMessage = false
    },
    closePopup() {
      this.amzInventoryUpdaterPopup.hide()
    },
    handleSubmit() {
      if (this.onHand === this.newQuantity) {
        Notify.warning('No change has been detected!')
        return
      }
      this.updateOnHand()
      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
