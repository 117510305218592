/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import inventoryService from '@/http/requests/inventory/inventoryService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    // if (filters === null) return {}

    const response = await inventoryService.amazonInventoryQuery(filters, pageableQuery)
    const data = response.content
    return {
      data: data,
      totalCount: response.totalElements,
    }
  },
})

const amazonInventorySource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  amazonInventorySource,
}
